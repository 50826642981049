import React from 'react'
import './thank.css'

const Thank = () => {
  return (
    <div className="thank_block container">
      <h1 className="thank_text">Дякуємо за Вашу заявку!</h1>
      <p className="thank_subtitle">Ми звʼяжемося з вами протягом 15 хв!</p>
      <a className="btn_thank_text" href={'/'}>
        <button className="btn_thank">Повернутись на головну сторінку</button>{' '}
      </a>
    </div>
  )
}

export default Thank
