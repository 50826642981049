import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import style from './MainPopUp.module.css'
import { useForm } from 'react-hook-form'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { useNavigate } from 'react-router-dom'
import { PhoneInput } from 'react-international-phone'
import { baseURL, chatId, validator } from '../../Constants/urls'
import { ExitIcons } from '../../Assets/Icons/icons'
import 'react-international-phone/style.css'

const MainPopUp = ({ handleClose, show, tariff, props }) => {
  const [phone, setPhone] = useState('')
  const [inName, setInName] = useState('')
  const [isBlurred, setIsBlurred] = useState(false)
  const [isBlurredName, setIsBlurredName] = useState(false)

  const phoneUtil = PhoneNumberUtil.getInstance()
  const isPhoneValid = () => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
    } catch (error) {
      return false
    }
  }
  const isValid = isPhoneValid(phone)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: 'all' })

  const navigate = useNavigate()

  const onSubmit = ({ name }) => {
    let msg = `Заявка на курс:\nІм'я - ${name}\nТелефон - ${phone}\nТариф - ${tariff}`

    if (name && isValid) {
      fetch(baseURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          validator: validator,
          chat_id: chatId,
          message: msg,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then((data) => {
          reset()
          navigate('/thankyou')
          setIsBlurredName(false)
        })
        .catch((error) => {
          console.error('Помилка при виконанні запиту:', error)
        })
    }
  }

  let redBord = {
    border: '1px solid red',
    boxShadow: 'inset 0px 0px 20px 1px rgba(255,0,0,.3)',
  }
  let greenBord = {
    border: '1px solid green',
    boxShadow: 'inset 0px 0px 20px 10px rgba(100,255,100,.3)',
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
      className={style.myModal}
    >
      <Modal.Body>
        <div className={style.img_close_block}>
          <img
            className={style.img_close}
            src={ExitIcons}
            alt=""
            onClick={handleClose}
          />
        </div>

        <form
          action=""
          className={style.myModal_form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <h2 className={style.myModal_form__title}>
            ЗВОРОТНИЙ <span>ЗВ'ЯЗОК</span>
          </h2>
          <h5 className={style.myModal_form__subTitle}>
            Вкажіть ваше ім'я та номер телефону і ми зателефонуємо протягом 15
            хв!
          </h5>
          <div className={style.myModal_form__box}>
            <label htmlFor="name" className={style.myModal_form__box_txt}>
              Ім’я
            </label>
            <input
              type="text"
              id={'name'}
              placeholder={'Ім’я'}
              className={style.myModal_form__box_inp}
              onInput={(name) => setInName(name.target.value)}
              style={
                errors.name
                  ? redBord
                  : !errors.name && isBlurredName && inName
                  ? greenBord
                  : null
              }
              onFocus={() => setIsBlurredName(true)}
              autoComplete={'off'}
              {...register('name', {
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
            />
          </div>
          <div className={style.myModal_form__box}>
            <label htmlFor="name" className={style.myModal_form__box_txt}>
              Телефон
            </label>
            <span className={style.myModal_form__box_inp__tel}>
              <PhoneInput
                defaultCountry="ua"
                className={style.myModal_form__box_inp}
                autoComplete={'off'}
                onChange={(phone) => setPhone(phone)}
                style={
                  !isValid && isBlurred
                    ? redBord
                    : isValid && isBlurred
                    ? greenBord
                    : null
                }
                onBlur={() => setIsBlurred(true)}
              />
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="7"
              viewBox="0 0 7 7"
              fill="none"
              className={style.myModal_form__box_dot}
            >
              <circle cx="3.5" cy="3.5" r="3.5" fill="#FFAC1C" />
            </svg>
          </div>

          <div className={style.myModal_form__box}>
            <label htmlFor="tariff" className={style.myModal_form__box_txt}>
              Тариф
            </label>
            <input
              type="text"
              id={'tariff'}
              placeholder={'Тариф'}
              value={tariff}
              className={style.myModal_form__box_inp}
              readOnly
            />
          </div>

          <div className={style.myModal_form__boxBtn}>
            <button className={style.myModal_form__boxBtn_btn} type={'submit'}>
              Записатися
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export { MainPopUp }
