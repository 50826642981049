import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MainPage from './Pages/MainPage/MainPage'
import Thank from './Pages/ThankyouPage/Thank'
import React from 'react'

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<MainPage />} />
          <Route path="/thankyou" element={<Thank />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
