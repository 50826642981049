import React, { useState } from 'react'
import './prices.css'
import {
  CheckBlue,
  CheckGrey,
  PriceFull,
  PriceStart,
  PriceVip,
} from '../../Assets/Icons/Prices'
import { MainPopUp } from '../MainPopUp/MainPopUp'

const texts1 = [
  'брокерський рахунок',
  'перша акція',
  'акції S&P500',
  'перші дивіденди',
  'перший пасивний дохід',
]

const textsgrey1 = [
  'індивідуальна стратегія',
  'індивідуальний портфель',
  'навики аналізу акцій',
  'навики роботи із спецсайтами',
  'навик підбору ETF за критеріями',
  'трекер-інвестицій (як вести свої інвестиції)',
  '10 брошур з фінансової грамотності',
  'індивідуальний фінансовий аналіз',
  'розробка особистого фінансового плану',
  'коуч-супровід протягом 1 року',
]

const texts2 = [
  'брокерський рахунок',
  'перша акція',
  'акції S&P500',
  'перші дивіденди',
  'перший пасивний дохід',
  'індивідуальна стратегія',
  'індивідуальний портфель',
  'навики аналізу акцій',
  'навики роботи із спецсайтами',
  'навик підбору ETF за критеріями',
  'трекер-інвестицій (як вести свої інвестиції)',
  '10 брошур з фінансової грамотності',
]

const textsgrey2 = [
  'індивідуальний фінансовий аналіз',
  'розробка особистого фінансового плану',
  'коуч-супровід протягом 1 року',
]

const texts3 = [
  'брокерський рахунок',
  'перша акція',
  'акції S&P500',
  'перші дивіденди',
  'перший пасивний дохід',
  'індивідуальна стратегія',
  'індивідуальний портфель',
  'навики аналізу акцій',
  'навики роботи із спецсайтами',
  'навик підбору ETF за критеріями',
  'трекер-інвестицій (як вести свої інвестиції)',
  '10 брошур з фінансової грамотності',
  'індивідуальний фінансовий аналіз',
  'розробка особистого фінансового плану',
  'коуч-супровід протягом 1 року',
]

const Prices = () => {
  const [show, setShow] = useState(false)
  const [selectedTariff, setSelectedTariff] = useState('')

  const handleClose = () => setShow(false)
  const handleShow = (tariffName) => {
    setSelectedTariff(tariffName)
    setShow(true)
  }

  return (
    <div className="prices_block" id={'price'}>
      <div className="customContainer">
        <div>
          <h1 className="prices_title">
            Виберіть зручний <br /> тариф для участі
          </h1>
        </div>
        <div className="prices_cards_block">
          <div className="price_card">
            <div className="price_block_name">
              <img
                className="price_paket_img"
                src={PriceStart}
                alt="PriceStart"
              />
              <h2 className="price_paket_name">СТАРТ</h2>
            </div>
            <div>
              <p className="price_subtitle_text">
                хто хоче розібратися і спробувати фондовий ринок
              </p>
            </div>
            <div className="paket_price">
              <div className="old_price_block">
                <h2 className="old_price">3900 грн</h2>
              </div>
              <h1 className="new_price">2900 грн</h1>
              <h3 className="price_span_text">Зі знижкою</h3>
            </div>
            <div>
              <p className="prices_offer">
                <span className="price_span_text">У вас:</span> немає капіталу{' '}
                <br />
                або він мінімальний
              </p>
            </div>
            <h2 className="price_span_text">Результат</h2>
            <div className="prices_block_list">
              {texts1.map((text, index) => (
                <div className="check_block" key={index}>
                  <img
                    className="prices_check_icon"
                    src={CheckBlue}
                    alt="CheckBlue"
                  />
                  <p className="prices_list_text">{text}</p>
                </div>
              ))}

              {textsgrey1.map((text, index) => (
                <div className="check_block" key={index}>
                  <img
                    className="prices_check_icon"
                    src={CheckGrey}
                    alt="CheckGrey"
                  />
                  <p className="prices_list_text prices_list_text_grey">
                    {text}
                  </p>
                </div>
              ))}
            </div>
            <div className="prices_block_btn">
              <p className="lesson_number">35 відеоуроків</p>
              <button
                onClick={() => handleShow('СТАРТ')}
                className="prices_btn"
              >
                Записатися
              </button>
              <p className="prices_btn_text">
                <span className="price_span_text">Тривалість:</span> 1 місяць
              </p>
            </div>
          </div>
          <div className="price_card">
            <div className="price_block_name">
              <img
                className="price_paket_img"
                src={PriceFull}
                alt="PriceFull"
              />
              <h2 className="price_paket_name">ПОВНИЙ</h2>
            </div>
            <div>
              <p className="price_subtitle_text">
                Хто хоче навчитися аналізувати акції та скласти свій портфель
              </p>
            </div>
            <div className="paket_price">
              <div className="old_price_block">
                <h2 className="old_price">9900 грн</h2>
              </div>
              <h1 className="new_price">7900 грн</h1>
              <h3 className="price_span_text">Зі знижкою</h3>
            </div>
            <div>
              <p className="prices_offer">
                <span className="price_span_text">У вас:</span> присутній деякий
                капітал або щомісячний залишок
              </p>
            </div>
            <h2 className="price_span_text">Результат</h2>
            <div className="prices_block_list">
              {texts2.map((text, index) => (
                <div className="check_block" key={index}>
                  <img
                    className="prices_check_icon"
                    src={CheckBlue}
                    alt="CheckBlue"
                  />
                  <p className="prices_list_text">{text}</p>
                </div>
              ))}

              {textsgrey2.map((text, index) => (
                <div className="check_block" key={index}>
                  <img
                    className="prices_check_icon"
                    src={CheckGrey}
                    alt="CheckGrey"
                  />
                  <p className="prices_list_text prices_list_text_grey">
                    {text}
                  </p>
                </div>
              ))}
            </div>
            <div className="prices_block_btn">
              <p className="lesson_number">60 відеоуроків</p>
              <button
                onClick={() => handleShow('ПОВНИЙ')}
                className="prices_btn"
              >
                Записатися
              </button>
              <p className="prices_btn_text">
                <span className="price_span_text">Тривалість:</span> 2 міс
                навчання
              </p>
            </div>
          </div>
          <div className="price_card">
            <div className="price_block_name">
              <img className="price_paket_img" src={PriceVip} alt="PriceVip" />
              <h2 className="price_paket_name">VIP</h2>
            </div>
            <div>
              <p className="price_subtitle_text">
                Хто хоче мати супровід (працювати не самостійно)
              </p>
            </div>
            <div className="paket_price">
              <div className="old_price_block">
                <h2 className="old_price">19900 грн</h2>
              </div>
              <h1 className="new_price">14900 грн</h1>
              <h3 className="price_span_text">Зі знижкою</h3>
            </div>
            <div>
              <p className="prices_offer">
                <span className="price_span_text">У вас:</span> комплексний
                фінансовий план та супровід протягом року
              </p>
            </div>
            <h2 className="price_span_text">Результат</h2>
            <div className="prices_block_list">
              {texts3.map((text, index) => (
                <div className="check_block" key={index}>
                  <img
                    className="prices_check_icon"
                    src={CheckBlue}
                    alt="CheckBlue"
                  />
                  <p className="prices_list_text">{text}</p>
                </div>
              ))}
            </div>
            <div className="prices_block_btn">
              <p className="lesson_number">1 рік супроводу</p>
              <button onClick={() => handleShow('VIP')} className="prices_btn">
                Записатися
              </button>
              <p className="prices_btn_text">
                <span className="price_span_text">Тривалість:</span> 2 міс
                навчання та супровід протягом 1 року
              </p>
            </div>
          </div>
        </div>
      </div>
      <MainPopUp
        handleClose={handleClose}
        show={show}
        tariff={selectedTariff}
      />
    </div>
  )
}

export default Prices
